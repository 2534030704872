import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'app-edit-subitems-dialog',
    templateUrl: './edit-subitems-dialog.component.html',
    styleUrls: [],
    standalone: true,
    imports: [FormsModule, MatDialogModule, MatIconModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatTooltipModule]
})
export class EditSubitemsDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<EditSubitemsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { subitems: { label: string, url: string }[] }
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    addSubitem(): void {
        this.data.subitems.push({ label: '', url: '' });
    }

    removeSubitem(index: number): void {
        this.data.subitems.splice(index, 1);
    }
}
