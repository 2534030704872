<h1 mat-dialog-title>Edit Subitems</h1>
<br>
<div mat-dialog-content>
  @for (subitem of data.subitems; track subitem; let i = $index) {
    <mat-form-field appearance="outline">
      <mat-label>Subitem Label {{ i + 1 }}</mat-label>
      <input matInput [(ngModel)]="subitem.label" placeholder="Label">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Subitem URL {{ i + 1 }}</mat-label>
      <input matInput [(ngModel)]="subitem.url" placeholder="URL">
    </mat-form-field>
    <button mat-icon-button matTooltip="Remove subitem" (click)="removeSubitem(i)">
      <mat-icon>delete</mat-icon>
    </button>
  }
  <button mat-raised-button color="primary" (click)="addSubitem()">Add Subitem</button>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="data.subitems">Save</button>
</div>
