import { Component, inject, OnInit } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ApiNavigationItems, NavItemsApi } from '@tytapp/api';
import { AppConfig } from '@tytapp/common';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavItem } from '@tytapp/app/nav/nav.types';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { EditSubitemsDialogComponent } from './edit-subitems-dialog/edit-subitems-dialog.component';


@Component({
  selector: 'tyt-nav-item-editor',
  standalone: true,
    imports: [
        MatListModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        CdkDropList,
        CdkDrag,
        MatInputModule,
        MatTooltipModule,
        AsyncPipe,
        NgIf,
        FormsModule,
        NgForOf,
        MatDialogModule,
        EditSubitemsDialogComponent,
        CdkDragHandle
    ],
  templateUrl: './nav-item-editor.component.html',
  styleUrl: './nav-item-editor.component.scss'
})
export class NavItemEditorComponent implements OnInit {
    navItems$ = new BehaviorSubject<ApiNavigationItems>(null);
    public appConfig = inject(AppConfig);
    private navItemsApi = inject(NavItemsApi);
    private matSnackBar = inject(MatSnackBar);

    constructor(private dialog: MatDialog) {}

    ngOnInit() {
        this.navItemsApi.getNavItems().subscribe({
            next: (navItems) => {
                this.navItems$.next(navItems);
            },
            error: (err) => {
                console.error('Error fetching navigation items:', err);
            }
        });
    }

    drop(event: CdkDragDrop<NavItem[]>, type: string, section?: string) {
        const navItems = this.navItems$.getValue();
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }

        if (type === 'desktop') {
            if (section === 'primary') {
                navItems[1].desktop.primary = [...event.container.data];
            } else if (section === 'secondary') {
                navItems[1].desktop.secondary = [...event.container.data];
            }
        } else if (type === 'mobile') {
            navItems[2].mobile = [...event.container.data];
        }

        this.navItems$.next(navItems);
    }

    duplicateItem(item: any, type: string, section?: string) {
        const navItems = this.navItems$.getValue();
        const newItem = { ...item };
        if (type === 'desktop') {
            navItems[1].desktop[section].push(newItem);
        } else if (type === 'mobile') {
            navItems[2].mobile.push(newItem);
        }
        this.navItems$.next(navItems);
    }

    removeItem(item: NavItem, type: string, section?: string) {
        const navItems = this.navItems$.getValue();
        if (type === 'desktop') {
            navItems[1].desktop[section] = navItems[1].desktop[section].filter(navItem => navItem !== item);
        } else if (type === 'mobile') {
            navItems[2].mobile = navItems[2].mobile.filter(navItem => navItem !== item);
        }
        this.navItems$.next(navItems);
    }

    saveNavItems() {
        const navItems = this.navItems$.getValue();
        const body = { nav_items: navItems };
        // @ts-ignore
        this.navItemsApi.setNavItems(body).subscribe(response => {
            this.matSnackBar.open('Navigation items saved successfully!', undefined, { duration: 5000 });
        });
    }

    openEditSubitemsDialog(item: NavItem): void {
        const dialogRef = this.dialog.open(EditSubitemsDialogComponent, {
            width: '250px',
            data: { subitems: [...item.subitems] }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                item.subitems = result;
                this.navItems$.next(this.navItems$.getValue());
            }
        });
    }
}
