<div class="items-actions">
  <button mat-raised-button color="primary" (click)="saveNavItems()">Save Changes</button>
</div>
<div class="nav-editor">
  <h2>Desktop Navigation</h2>
  <div class="desktop-nav">
    <div class="nav-section">
      <h3>Primary</h3>
        @if (navItems$ | async; as navItems) {
        <mat-list cdkDropList (cdkDropListDropped)="drop($event, 'desktop', 'primary')" [cdkDropListData]="navItems[1].desktop.primary">
            @for (item of navItems[1].desktop.primary; track item; let i = $index) {
              <mat-list-item cdkDrag>
                <div class="nav-item-content">
                  <mat-icon cdkDragHandle matTooltip="Drag to reorder">drag_handle</mat-icon>
                  @if(item?.icon !== undefined) {
                    <mat-form-field appearance="outline">
                      <mat-label>Menu Icon</mat-label>
                      <input matInput [(ngModel)]="item.icon" placeholder="Icon">
                    </mat-form-field>
                  }
                  <mat-form-field appearance="outline">
                    <mat-label>Menu Item</mat-label>
                    <input matInput [(ngModel)]="item.label" placeholder="Label" class="nav-item-input-field">
                  </mat-form-field>
                  @if(!item?.subitems) {
                    <mat-form-field appearance="outline">
                      <mat-label>URL</mat-label>
                      <input matInput [(ngModel)]="item.url" placeholder="Label">
                    </mat-form-field>
                  }
                  <button mat-icon-button matTooltip="Duplicate menu item" (click)="duplicateItem(item, 'desktop', 'primary')">
                    <mat-icon>content_copy</mat-icon>
                  </button>
                  <button mat-icon-button matTooltip="Remove menu item" (click)="removeItem(item, 'desktop', 'primary')">
                    <mat-icon>delete</mat-icon>
                  </button>
                  @if(item?.subitems) {
                    <button mat-icon-button [matMenuTriggerFor]="subMenu">
                      <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <mat-menu #subMenu="matMenu">
                      @for(subitem of item.subitems; track subitem) {
                        <button mat-menu-item>{{ subitem.label }}</button>
                      }
                    </mat-menu>
                    <button mat-icon-button matTooltip="Edit subitems" (click)="openEditSubitemsDialog(item)">
                      <mat-icon>edit</mat-icon>
                    </button>
                  }
                </div>
              </mat-list-item>
            }
        </mat-list>
        }
    </div>
    <div class="nav-section">
      <h3>Secondary</h3>
        @if (navItems$ | async; as navItems) {
        <mat-list cdkDropList (cdkDropListDropped)="drop($event, 'desktop', 'secondary')" [cdkDropListData]="navItems[1].desktop.secondary">
            @for (item of navItems[1].desktop.secondary; track item; let i = $index) {
              <mat-list-item cdkDrag>
                <div class="nav-item-content">
                  <mat-icon cdkDragHandle matTooltip="Drag to reorder">drag_handle</mat-icon>
                  @if(item?.icon !== undefined) {
                    <mat-form-field appearance="outline">
                      <mat-label>Menu Icon</mat-label>
                      <input matInput [(ngModel)]="item.icon" placeholder="Icon">
                    </mat-form-field>
                  }
                  <mat-form-field appearance="outline">
                    <mat-label>Menu Item</mat-label>
                    <input matInput [(ngModel)]="item.label" placeholder="Label">
                  </mat-form-field>
                  @if(!item?.subitems) {
                    <mat-form-field appearance="outline">
                      <mat-label>URL</mat-label>
                      <input matInput [(ngModel)]="item.url" placeholder="Label">
                    </mat-form-field>
                  }
                  <button mat-icon-button matTooltip="Duplicate menu item" (click)="duplicateItem(item, 'desktop', 'secondary')">
                    <mat-icon>content_copy</mat-icon>
                  </button>
                  <button mat-icon-button matTooltip="Remove menu item" (click)="removeItem(item, 'desktop', 'secondary')">
                    <mat-icon>delete</mat-icon>
                  </button>
                  @if(item?.subitems) {
                    <button mat-icon-button [matMenuTriggerFor]="subMenu">
                      <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <mat-menu #subMenu="matMenu">
                      @for(subitem of item.subitems; track subitem) {
                        <button mat-menu-item>{{ subitem.label }}</button>
                      }
                    </mat-menu>
                    <button mat-icon-button matTooltip="Edit subitems" (click)="openEditSubitemsDialog(item)">
                      <mat-icon>edit</mat-icon>
                    </button>
                  }
                </div>
              </mat-list-item>
            }
        </mat-list>
        }
    </div>
  </div>
  <h2>Mobile Navigation</h2>
  <div class="mobile-nav">
    <div class="nav-section">
      @if (navItems$ | async; as navItems) {
      <mat-list cdkDropList (cdkDropListDropped)="drop($event, 'mobile')" [cdkDropListData]="navItems[2].mobile">
        @for (item of navItems[2].mobile; track item; let i = $index) {
        <mat-list-item cdkDrag>
          <div class="nav-item-content">
            <mat-icon cdkDragHandle matTooltip="Drag to reorder">drag_handle</mat-icon>
            @if(item?.icon !== undefined) {
              <mat-form-field appearance="outline">
                <mat-label>Menu Icon</mat-label>
                <input matInput [(ngModel)]="item.icon" placeholder="Icon">
              </mat-form-field>
            }
            <mat-form-field appearance="outline">
              <mat-label>Menu Item</mat-label>
              <input matInput [(ngModel)]="item.label" placeholder="Label">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>URL</mat-label>
              <input matInput [(ngModel)]="item.url" placeholder="Label">
            </mat-form-field>
            <button mat-icon-button matTooltip="Duplicate menu item" (click)="duplicateItem(item, 'mobile')">
              <mat-icon>content_copy</mat-icon>
            </button>
            <button mat-icon-button matTooltip="Remove menu item" (click)="removeItem(item, 'mobile')">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-list-item>
        }
      </mat-list>
      }
    </div>
    <div class="mobile-menu-preview">
      <h3>Mobile Menu Preview</h3>
      <mat-list>
        @if (navItems$ | async; as navItems) {
          @for (item of navItems[2].mobile; track item) {
            <mat-list-item>
              <div class="preview-item">
                @if(item?.icon !== undefined) {
                  <mat-icon>{{ item.icon }}</mat-icon>
                }
                <span>{{ item.label }}</span>
              </div>
            </mat-list-item>
          }
        }
      </mat-list>
    </div>
  </div>
</div>
<div class="items-actions">
  <button mat-raised-button color="primary" (click)="saveNavItems()">Save Changes</button>
</div>
